






















































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, Action } from 'vuex-class';
import { LAYOUT } from '@/store/layout/constants';
import { CONST as AUTH } from '@/store/auth/constants';
import { User } from '@/store/auth/types';
import BreadCrumbs from '../basics/BreadCrumbs.vue';
import { EventAggregator } from '@/events';
import { EVN__EQUIP_TREE_HIDDEN } from '@/events/constants';
import InlineSvg from 'vue-inline-svg';
import { ITopMenuItem } from '@/components/top-menu/types';
import MenuItem from '@/components/top-menu/menu-item.vue';
import { ROUTES } from '@/router/router.constants';
import StatusBar from '@/components/status-bar/index.vue';
import BookmarksIcon from '@/components/bookmarks/bookmarks-icon.vue';

@Component({
  components: {
    'bread-crumbs': BreadCrumbs,
    'inline-svg': InlineSvg,
    'menu-item': MenuItem,
    'status-bar': StatusBar,
    'bookmarks-icon': BookmarksIcon,
  },
})
export default class TopMenu extends Vue {
  activeLink = '';
  selectedIndex = '';
  handleSelect(key: any) {
    this.selectedIndex = key;
  }

  mounted() {
    this.activeLink = this.$route.path;
  }
  @Watch('$route') onRoute(to: any, from: any) {
    if (to === from) return;
    if (to.path.includes(ROUTES.EQUIPMENT)) {
      this.activeLink = ROUTES.HOME;
    } else {
      for (const route of this.menuItems.map((r) => r.route).filter((f) => f !== ROUTES.HOME)) {
        if (to.path.includes(route)) {
          this.activeLink = route;
          break;
        }
      }
    }
  }

  transformSvgSrc = (svg: any) => {
    const children = (svg as HTMLElement)?.children;
    if (!children?.length) return svg;
    for (const child of children) {
      child?.setAttributeNS(null, 'fill', '#FFFFFF');
    }
    return svg;
  };

  @Getter(LAYOUT.GET_DRAWER_VISIBLE, { namespace: LAYOUT.LAYOUT }) drawerVisible!: boolean;
  @Getter(AUTH.GET_USER, { namespace: AUTH.AUTH }) user?: User | null;
  @Mutation(LAYOUT.SET_DRAWER_VISIBLE, { namespace: LAYOUT.LAYOUT }) setDrawerVisible!: Function;
  @Action(AUTH.LOGOUT, { namespace: AUTH.AUTH }) logoutAction: any;

  toggleDrawer() {
    this.setDrawerVisible(!this.drawerVisible);
  }

  @Watch('drawerVisible') onDrawerVisible(val: boolean, old: boolean) {
    if (val === old) return;
    EventAggregator?.publish({ theme: EVN__EQUIP_TREE_HIDDEN });
  }

  menuItems: ITopMenuItem[] = [
    {
      note: 'Журнал событий',
      type: 'event-journal',
      index: '0',
      route: ROUTES.EVENT_JOURNAL,
      border: '1px solid #656464',
    },
    {
      note: 'Мониторинг',
      type: 'monitoring',
      index: '1',
      route: ROUTES.HOME,
    },
    {
      note: 'Источники данных',
      type: 'sensors',
      index: '2',
      route: ROUTES.SENSORS,
      border: '1px solid #656464',
    },
    {
      note: 'Журнал обновления',
      type: 'journal',
      index: '3',
      route: ROUTES.UPDATE_JOURNALS,
    },
    {
      note: 'Пользователи и роли',
      type: 'users',
      index: '4',
      route: ROUTES.ADMIN,
      border: '1px solid #656464',
    },
    {
      note: 'Протокол работы системы',
      type: 'system-logs',
      index: '5',
      route: ROUTES.SYSTEM_LOGS,
    },
    {
      note: 'Статистика',
      type: 'usage-info',
      index: '6',
      route: ROUTES.USAGE_INFO,
      border: '1px solid #656464',
    },
  ];

  async logout() {
    await this.logoutAction();
    this.$router.push('/login');
  }
}
