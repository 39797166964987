






import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import * as scroll from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.use(scroll.default);
@Component
export default class CustomScrollBar extends Vue {
  @Ref('scrollBar')
  readonly scrollBar!: any;
  windowHeight = 0;
  @Watch('windowHeight')
  windowHeightChanged(newHeight: number, oldHeight: number) {
    if (newHeight === oldHeight) return;
    this.onResize();
  }
  @Prop() readonly sizeAdder?: number;
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('error', (err) => false );
      window.addEventListener('resize', this.onResize);
    });
    this.onResize();
  }
  onResize() {
    // 55 is top menu height
    if (this.scrollBar && this.sizeAdder) {
      this.scrollBar.ps.element.style.maxHeight = `calc(${window.innerHeight}px -
        ${Number(this.sizeAdder)})`;
    }
  }
}
