

















import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

@Component
export default class CustomHeader extends Vue {
  @Ref('content') content: any;
  @Ref('border') border: any;

  @Prop() width!: number;
  @Prop() minSize?: number;

  @Watch('width') onResize(next: number) {
    this.content.style.width = next + 'px';
  }

  setEvents() {
    const el = this.content;

    const resize = (e: MouseEvent) => {
      e.preventDefault();
      e.clientX >= (this.minSize || 200) && this.$emit('on-resize', e.clientX);
    };

    this.border.addEventListener(
      'mousedown',
      (e: MouseEvent) => {
        e.preventDefault();
        this.content.style.transition = 'initial';
        document.body.style.cursor = 'ew-resize';
        document.addEventListener('mousemove', resize, false);
      },
      false
    );

    document.addEventListener(
      'mouseup',
      () => {
        el.style.transition = '';
        document.body.style.cursor = '';
        document.removeEventListener('mousemove', resize, false);
      },
      false
    );
  }

  mounted() {
    this.setEvents();
    this.onResize(this.width);
  }
}
