import { render, staticRenderFns } from "./CustomScrollBar.vue?vue&type=template&id=25aa7303&scoped=true&"
import script from "./CustomScrollBar.vue?vue&type=script&lang=ts&"
export * from "./CustomScrollBar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25aa7303",
  null
  
)

export default component.exports