


























import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  RESET_STATUS_BAR_HISTORY,
  STATUS_BAR,
  STATUS_BAR_HISTORY,
} from '@/store/status-bar/constants';
import { IStatusRecord } from '@/store/status-bar/types';
@Component({
  filters:{
    getDuration: (val:any) => {
      const {start, end} = val;
      let dur;
      if(start && end){
         dur = Math.abs( end.getTime() - start.getTime());
      }
      return dur ?? '-';
    }
  }
})
export default class StatusBarPopup extends Vue {
  @Getter(STATUS_BAR_HISTORY, { namespace: STATUS_BAR })
  history!: IStatusRecord[];
  @Action(RESET_STATUS_BAR_HISTORY, { namespace: STATUS_BAR })
  resetStatusHistory!: () => void;
  @Watch('$route.query', { immediate: true }) onQuery(val:any, old:any) {
    if (val === old) return;
    this.resetStatusHistory();
  }

  getDuration(val: any){
    return val;
  }

}
