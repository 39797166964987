












import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { BREADCRUMBS } from '@/store/breadcrumbs/constants';

@Component
export default class BreadCrumbs extends Vue {
  @Getter(BREADCRUMBS.GET_BREADCRUMBS, { namespace: BREADCRUMBS.BREADCRUMBS })
  breadcrumbs!: string[];

  get lastCrumb() {
    if (this.breadcrumbs.length > 0) {
      return this.breadcrumbs[this.breadcrumbs.length - 1];
    } else {
      return '';
    }
  }

  get nonLastCrumbs() {
    return this.breadcrumbs.slice(0, (this.breadcrumbs.length || 1) - 1);
  }
}
