













import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BookmarksMenu from '@/components/bookmarks/bookmarks-menu.vue';
import BookmarksTree from '@/components/bookmarks/bookmarks-tree.vue';

@Component({
  components: {
    BookmarksTree,
    BookmarksMenu,
  },
})
export default class BookmarksContainer extends Vue {}
