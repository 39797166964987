import { render, staticRenderFns } from "./bookmarks-container.vue?vue&type=template&id=2d623d01&scoped=true&"
import script from "./bookmarks-container.vue?vue&type=script&lang=ts&"
export * from "./bookmarks-container.vue?vue&type=script&lang=ts&"
import style0 from "./bookmarks-container.vue?vue&type=style&index=0&id=2d623d01&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d623d01",
  null
  
)

export default component.exports