






















import { IBookmarkNode } from '@/store/bookmarks/types';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Action } from 'vuex-class';
import { BOOKMARKS, DELETE_NODE, UPDATE_NODE } from '@/store/bookmarks/constants';

@Component
export default class BookmarksTreeItem extends Vue {
  @Prop() treeNode!: any;
  @Prop() isTimeFlag!: boolean;
  isEditMode = false;
  nodeAlias?: string = '';
  onNodeDoubleClick({ data }: any) {
    this.isEditMode = true;
    this.nodeAlias = (data as IBookmarkNode).alias;
  }

  @Action(DELETE_NODE, { namespace: BOOKMARKS })
  deleteNodeAction!: ({ uuid }: { uuid: string }) => void;
  onDelete({ data }: any) {
    this.deleteNodeAction({ uuid: data.uuid });
  }

  get isFolder() {
    const node = this.treeNode.data as IBookmarkNode;
    return node && node.type !== 'link';
  }

  get route() {
    const node = this.treeNode.data as IBookmarkNode;
    if (!node) return null;
    return this.isTimeFlag ? node.urlWithQuery : node.url;
  }

  onCancel() {
    this.isEditMode = false;
  }

  @Action(UPDATE_NODE, { namespace: BOOKMARKS })
  updateNodeAction!: (payload: any) => void;
  onSave(node: any) {
    this.updateNodeAction({ alias: this.nodeAlias, node: node.data });
    this.isEditMode = false;
  }

  defineIcon = (node: any) => {
    const { data, expanded } = node;
    return data.type === 'link'
      ? 'las la-link'
      : data.type === 'folder'
      ? expanded
        ? 'el-icon-folder-opened'
        : 'el-icon-folder'
      : '';
  };
}
