
















import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import {
  BOOKMARKS,
  BOOKMARKS_TREE,
  SAVE_BOOKMARKS_TREE,
  BOOKMARKS_QUERY,
} from '@/store/bookmarks/constants';
import { IBookmarkNode, IBookmarksQuery } from '@/store/bookmarks/types';
import BookmarksTreeItem from '@/components/bookmarks/bookmarks-tree-item.vue';
import { EventAggregator } from '@/events';
import {
  ADD_TIME_FLAG,
  BOOKMARKS_VISIBILITY_FLAG,
  MAKE_RANGE_REQUEST,
} from '@/components/bookmarks/bookmarks-constants';
import { Route } from 'vue-router';
import { CONST } from '@/store/auth/constants';
import { User } from '@/store/auth/types';
@Component({
  components: { BookmarksTreeItem },
})
export default class BookmarksTree extends Vue {
  @Getter(BOOKMARKS_TREE, { namespace: BOOKMARKS })
  bookmarksTreeGetter!: IBookmarkNode[];

  props = {
    type: 'type',
    label: 'alias',
    children: 'children',
  };

  get tree() {
    return this.bookmarksTreeGetter;
  }

  isTimeFlag = true;
  isPopupFlag = false;

  allowDrop = (draggingNode: any, dropNode: any) => dropNode.data.type === 'folder';

  @Getter(CONST.GET_USER, { namespace: CONST.AUTH }) $user?: User | null;
  get userId() {
    return this.$user?.id ?? null;
  }
  @Action(SAVE_BOOKMARKS_TREE, { namespace: BOOKMARKS })
  saveBookmarksAction!: () => void;
  handleDrop(drag: any, drop: any) {
    // need save tree to db
    this.saveBookmarksAction();
  }

  @Action(BOOKMARKS_QUERY, { namespace: BOOKMARKS })
  updateBookmarksQueryAction!: (query: IBookmarksQuery) => void;
  mounted() {
    const { id } = this.$user as User;
    if (id) {
      this.updateBookmarksQueryAction({ userId: id });
    }

    EventAggregator.register(ADD_TIME_FLAG)?.subscribe((msg: boolean) => {
      this.isTimeFlag = msg;
    });
    EventAggregator.register(BOOKMARKS_VISIBILITY_FLAG)?.subscribe((msg: boolean) => {
      this.isPopupFlag = msg;
    });

    this.$router.afterEach((to: Route, from: Route) => {
      if (!this.isPopupFlag) return;
      if (this.isTimeFlag) return;
      if (from.fullPath.split('?')[0] !== to.fullPath.split('?')[0]) return;
      const fromSE = Object.keys(from.query).filter((i) => i === 'start' || i === 'end');
      const toSE = Object.keys(to.query).filter((i) => i === 'start' || i === 'end');
      if (fromSE?.length > 0 && toSE?.length <= 0) {
        //this.$router.go(to.fullPath as any);
        // todo kill hack
        EventAggregator.publish({
          message: MAKE_RANGE_REQUEST,
          theme: MAKE_RANGE_REQUEST,
        });
      }
    });
  }
}
