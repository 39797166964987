


















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  STATUS,
  STATUS_BAR,
  STATUS_ON_REQUEST,
  STATUS_ON_RESPONSE,
} from '@/store/status-bar/constants';
import { IStatus } from '@/store/status-bar/types';
import { StatusBarManager } from '@/store/status-bar/StatusBarManager';
import ResponseSign from '@/components/status-bar/response-sign.vue';
import StatusBarPopup from '@/components/status-bar-popup/index.vue';
@Component({
  components: { ResponseSign, StatusBarPopup  },
})
export default class StatusBar extends Vue {

  popup = new StatusBarPopup();


  @Getter(STATUS, { namespace: STATUS_BAR })
  status!: IStatus;
  @Action(STATUS_ON_REQUEST, { namespace: STATUS_BAR })
  onRequest!: (payload: any) => void;
  @Action(STATUS_ON_RESPONSE, { namespace: STATUS_BAR })
  onResponse!: (payload: any) => void;

  get isProgress(): boolean {
    return this.status?.statusType === 'progress';
  }

  getColoredIcon() {
    switch (this.status.statusType) {
      case 'progress':
        return this.getColor('las la-spinner');
      case 'done':
        return this.getColor('las la-check-double');
      default:
        return '';
    }
  }

  getColor(basic: string) {
    return basic + ' ' + this.status.statusType;
  }

  mounted() {
    StatusBarManager.onChange().subscribe(({ payload, activity }) => {
      switch (activity) {
        case 'request':
          this.onRequest(payload);
          break;
        case 'response':
          this.onResponse(payload);
          break;
        case 'error':
          this.onResponse(payload.response);
          break;
      }
    });
  }
}
