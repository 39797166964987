















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BookmarksContainer from '@/components/bookmarks/bookmarks-container.vue';
import { EventAggregator } from '@/events';
import { BOOKMARKS_VISIBILITY_FLAG } from '@/components/bookmarks/bookmarks-constants';
@Component({
  components: { 'bookmarks-container': BookmarksContainer },
})
export default class BookmarksIcon extends Vue {
  onVisibilityChanged(flag: boolean) {
    EventAggregator.publish({
      message: flag,
      theme: BOOKMARKS_VISIBILITY_FLAG,
    });
  }
}
