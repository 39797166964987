











import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import InlineSvg from 'vue-inline-svg';
@Component({
  components: {
    'inline-svg': InlineSvg,
  },
})
export default class MenuItem extends Vue {
  @Prop() note!: string;
  @Prop() path!: string;

  get iconFilePath() {
    return require(`../../assets/icons/svg/menu/${this.path}.svg`);
  }

  transformSvgSrc = (svg: any) => {
    const children = (svg as HTMLElement)?.children;
    if (!children?.length) return svg;
    for (const child of children) {
      child?.setAttributeNS(null, 'fill', '#FFFFFF');
    }
    return svg;
  };
}
