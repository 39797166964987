












import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Action } from 'vuex-class';
import { BOOKMARKS, GENERATE_FOLDER, GENERATE_LINK } from '@/store/bookmarks/constants';
import { EventAggregator } from '@/events';
import { ADD_TIME_FLAG } from '@/components/bookmarks/bookmarks-constants';
@Component
export default class BookmarksMenu extends Vue {
  useQueryTime = 'Время запроса';
  isQueryTime = false;

  @Action(GENERATE_LINK, { namespace: BOOKMARKS })
  generateLinkAction!: (payload: any) => void;
  @Action(GENERATE_FOLDER, { namespace: BOOKMARKS })
  generateFolderAction!: (payload: any) => void;

  onCheckboxChange = (payload: boolean) => {
    EventAggregator.publish({
      message: payload,
      theme: ADD_TIME_FLAG,
    });
  };

  payload() {
    const { fullPath, path, query } = this.$route;
    const book = { urlWithQuery: fullPath, url: path };
    return { book, query };
  }

  addLink() {
    this.generateLinkAction(this.payload());
  }
  addFolder() {
    this.generateFolderAction(this.payload());
  }

  mounted() {
    this.isQueryTime = true;
    EventAggregator.publish({
      message:  this.isQueryTime,
      theme: ADD_TIME_FLAG,
    });
  }
}
