











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
@Component
export default class ResponseSign extends Vue {
  @Prop() tasks?: number;
}
